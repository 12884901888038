<template>
	<div>
		<div style="font-size: 15px;background-color: white;height: 60px;font-weight: bold;padding-top: 19px;padding-left: 30px;">
		   修改密码
		</div>
		<div style="margin-top: 10px;background-color: white;padding: 40px;">
			<el-form ref="form" :model="form" label-width="80px" style="width: 800px;display: inline-block;margin-left: 270px;">
				<el-form-item label="原密码">
					<el-input type="password" v-model="form.original_password" style="width: 700px;"></el-input>
				</el-form-item>
				<el-form-item label="新密码">
					<el-input type="password" v-model="form.password" style="width: 700px;"></el-input>
				</el-form-item>
				<el-form-item label="确认密码">
					<el-input type="password" v-model="form.confirm_password" style="width: 700px;"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit" >保存</el-button>
				</el-form-item>
			</el-form>
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {}
			}
		},
		methods: {
			onSubmit() {
				if(!this.form.original_password){
					return this.$notify.error({message: '请输入原密码'});
				}
				if(!this.form.password){
					return this.$notify.error({message: '请输入新密码'});
				}
				if(!this.form.confirm_password){
					return this.$notify.error({message: '请输入确认密码'});
				}
				
				if(this.form.password != this.form.confirm_password){
					return this.$notify.error({message: '确认密码不一致'});
				}
				this.$post('sys-admin-password',this.form).then(res=>{
				    this.$notify({message: res.msg || '操作成功'});
				}).catch(err=>{
				    this.$notify.error({message:err||'操作失败'});
				})
			}
		}
	}
</script>
